import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import { Table } from 'react-bootstrap';
import _fetch from '../../config/api';
import dateformat from "dateformat";
import { useLocation, useParams } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import NoResult from './NoResult';
import { RefreshOutlined } from '@mui/icons-material';

const DirectIncome = (props: any) => {
    const { t } = useTranslation();

    const { type } = useParams();
    let location = useLocation();



    const [directIncome, setDirectIncome] = useState([]);
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [page, setpage] = useState(1);
    const [levelSearch, setLevelSearch] = useState('');
    const [searchedType, setSearchType] = useState('level');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [allLevels, setAllLevels] = useState([
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
    ]);
    const [keyCount, setKeycount] = useState(0);

    useEffect(() => {
        getIncomeReport();

    }, [levelSearch, page, type]);

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
        setStartDate('');
        setEndDate('')
    }, [type]);

    const getIncomeReport = async () => {
        setLoading(true);
        if (levelSearch) {
            if (startDate) {
                var searchData = `&startDate=${startDate}&endDate=${endDate}&level=${levelSearch}`;

            } else {
                var searchData = `&level=${levelSearch}`;
            }
        } else {
            var searchData = "";
        }
        if (startDate) {
            var searchData = `&startDate=${startDate}&endDate=${endDate}`;

        } else {
            var searchData = "";

        }
        if (type == 'staking_bonus' || type == 'booster_airdrop') {
            var res = await _fetch(`${api_url}report/incomes-v2?type=${type}&limit=10&page=${page}${searchData}`, "GET", {})
        } else {
            var res = await _fetch(`${api_url}report/incomes?type=${type}&limit=10&page=${page}${searchData}`, "GET", {})

        }


        if (res?.status === "success") {
            setLoading(false);
            setDirectIncome(res?.data?.data);
            setTotalRecord(res?.total);
            setResult(res?.results);
            setTotalAmount(res?.totalAmount);
        }

    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };



    // const links = [
    //     { name: t("Level Bonus"), path: '/report/level_income' },
    //     // { name: t("Direct Revenue"), path: '/report/direct_income' },
    //     // { name: t("Single Leg Revenue"), path: '/report/single_leg_income' },
    //     // { name: t("Reward Revenue"), path: '/report/reward_income' },
    //     { name: t("Loyalty Club Pool 1"), path: '/report/loyalty_club_pool1' },
    //     { name: t("Loyalty Club Pool 2"), path: '/report/loyalty_club_pool2' },
    //     { name: t("Loyalty Club Pool 2"), path: '/report/loyalty_club_pool3' },
    // ];



    return (
        <main>

            {/* <SupportTab links={links} /> */}


            {location.pathname === '/report/:type' ? "" : <div className="__refreshButton"><button type="button" className="btn" onClick={() => window.location.reload()} ><RefreshOutlined /></button></div>}


            <div className="uni-level-outer">
                <div className="level_inner">

                    <div className='__toptable'>
                        <div className='__levelHeading'>
                            <h5>
                                {location.pathname == '/report/direct_income' && <span> {t("Direct Revenue")}</span>}
                                {location.pathname == '/report/level_income' && <span> {t("Level  Bonus")}</span>}
                                {location.pathname == '/report/single_leg_income' && <span> {t("Single Leg Revenue")}</span>}
                                {location.pathname == '/report/reward_income' && <span> {t("Reward Revenue")}</span>}
                                {location.pathname == '/report/loyalty_club_pool1' && <span> {t("Loyalty Club Pool 1")}</span>}
                                {location.pathname == '/report/loyalty_club_pool2' && <span> {t("Loyalty Club Pool 2")}</span>}
                                {location.pathname == '/report/loyalty_club_pool3' && <span> {t("Loyalty Club Pool 3")}</span>}
                                <span className='value'> ( ${totalAmount?.toFixed(2)} )</span>
                            </h5>
                        </div>

                        <div className="top_section_unilevel">
                            <div className="date">
                                <label className='form_lable'>{t("From")}</label>   <input type='date' className="form-control h-auto" placeholder='' onChange={(e: any) => setStartDate(e.target.value)} value={startDate} />
                            </div>

                            <div className="date">
                                <label className='form_lable'>To</label>   <input type='date' className="form-control h-auto" placeholder='' onChange={(e: any) => setEndDate(e.target.value)} value={endDate} />
                            </div>

                            {type == 'level_income' && <div className='date'>
                                <label className='form_lable'>{t("Level")}</label>
                                <select className="form-control h-auto select_option" onChange={(e: any) => setLevelSearch(e.target.value)} >
                                    <option value="">All</option>

                                    {allLevels?.map((item: any, key: any) => (
                                        <option key={key} value={item}> {t('Level') + " " + item} </option>
                                    ))}
                                </select>
                            </div>}

                            <button type='button' className='seach_btn' onClick={() => getIncomeReport()}>{t("Search")}</button>
                        </div>
                    </div>




                    <div className="table_section mt-4">
                        <div className="scrolling_table">
                            <div className="scroll_under">
                                <div className="table_responsive">
                                    <Table className="support_table all_tables">
                                        <thead className='thead_dashboard'>
                                            <tr className="table_heading_row">
                                                <th>{t("Amount")}</th>

                                                <th>{t("Description")}</th>
                                                <th>{t("Type")}</th>
                                                <th>{t("Credit Type")}</th>
                                                <th>{t("Created Date")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody_dashboard'>
                                            {directIncome.length == 0 ? <tr className='no_data_tr'>
                                                <td colSpan={9}>
                                                    <NoResult />
                                                </td>
                                            </tr> :

                                                <>
                                                    {directIncome?.map((item: any, index: any) => {
                                                        return (
                                                            <>

                                                                <tr className="table_data_row">
                                                                    {type != 'staking_bonus' && type != 'booster_airdrop' && <td className="table_data text-success">  {`$${item?.amount?.toFixed(2)}`}  </td>}
                                                                    {type == 'booster_airdrop' && <td className="table_data">
                                                                        <div className="pic_text"> ${item?.dollar?.toFixed(2)} </div> </td>}
                                                                    {type == 'staking_bonus' && <td className="table_data">
                                                                        <div className="pic_text"> ${item?.dollar?.toFixed(2)} </div> </td>}
                                                                    <td className="table_data">{item?.description}</td>
                                                                    <td className="table_data">
                                                                        {item.type.replace('_', ' ')}
                                                                    </td>
                                                                    <td className="table_data">{item?.amount > 0 ? "Credit" : "Debit"}</td>
                                                                    <td className="table_data payment_fee">
                                                                        {dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}</>
                                            }
                                        </tbody>
                                    </Table>
                                    {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={1}
                                        pageCount={totalRecord / 10}
                                        previousLabel="<"
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default DirectIncome