import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import eng_icon from '../Assets/Icon/english.webp';
import Avtar from '../Assets/Icon/user-icon.png';
import { api_url, shortLogo, title } from '../config/Config';
import _fetch from '../config/api';
import toasted from '../config/toast';
// import { useLanguage } from "../LanguageContext";
import { Dropdown } from 'react-bootstrap';
import { AutoGraph, Close, Logout, ManageAccounts, ReceiptLong, Reorder, TipsAndUpdates } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { menuItems } from './MenuItems';


const Header = () => {


  // Language change code
  // const { selectedLanguage, changeLanguage } = useLanguage();
  // const [selectLangSelect, setselectLangSelect] = useState({
  //   value: 'en',
  //   text: <span className="new__a"> English</span>,
  //   icon: <img src={eng_icon} className="option-img-a" width="20px" />

  // });

   // const changeLanguageInContext = (language) => {
  //   setselectLangSelect(language)
  //   changeLanguage(language.value);
  //   localStorage.setItem('language', language?.value);
  // };

  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  
  const [userData, setuserData] = useState({ user_info: { email: '', income_earn: 0 } });
  const [isAvatar, setAvatar] = useState("");
  const [sponsorId, setSponsorId] = useState('');


  useEffect(() => {

    let userDetails = (localStorage.getItem("user"));
    var storeData = JSON.parse(userDetails)
    setuserData(storeData);

    (async () => {
      const data1 = await _fetch(`${api_url}user/userInfo/`, "GET", {});
      // console.log('data1?.message', data1?.message);
      if (data1?.message == 'You are not logged in! Please log in to get access.') {
        localStorage.clear();
        navigate("/login");
      }
      if (data1.status === 'success') {
        localStorage.setItem("api_called", 'false');
        setAvatar(data1?.data?.data?.display_profile);
        setSponsorId(data1?.data?.data?.username);
      }
    })();

  }, [location.pathname]);



  const LogOut = async () => {
    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});
    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }



  const [userDetails, setuserdetails] = useState({ name: "" });

  const userDataFound = async () => {

    const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
    if (data1.status === 'success') {
      localStorage.setItem("api_called", 'false');
      setuserdetails(data1?.data?.data);

    } else {
      // localStorage.clear();
      // navigate("/login");
    }
  }

  useEffect(() => {
    const api_called = localStorage.getItem('api_called');

    if (!api_called || api_called == 'false') {
      localStorage.setItem("api_called", 'true');
      userDataFound();
    }
  }, [location.pathname]);




  const handleButtonClick = () => {
    document.body.classList.toggle('show-sidebar');
  };


  return (
    <>
      <div className="pvt-header">
        <div className="container-index">
          <div className="header-area">
            <div className="left-area-header">
              <div className="header-logo">
                <Link to="/">
                  <img src={shortLogo} alt={title + 'Logo'} />
                </Link>
              </div>

              <ul className="nav lg__size">
                {menuItems.map((item, index, subMenu) => (

                  <li className={"nav-item " + `${item.subMenu ? " dropdown " : ''}` + `link-${item.eventKey}`} key={index}>
                    {item.subMenu == null && <Link to={item.path} className="nav-link">{item.label} </Link>}

                    {item.subMenu && (
                      <>
                        <Dropdown.Toggle className="nav-link">
                          {item.label}
                        </Dropdown.Toggle>

                        <div className='__drop-outer'>
                          <div className='__dropdown'>
                            {item.subMenu.map((subItem) => (
                              <Link data-bs-toggle="dropdown" key={subMenu.dropLinkName} className="dropdown-item" to={subItem.path}> <span>{subItem.icon}</span> {subItem.label} </Link>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </li>
                ))}
              </ul>

            </div>


            

            <div className='__end'>
              <div className='__item'>
                <Link to="/ledger" className='Link'> <ReceiptLong /></Link>
              </div>
              <div className="nav-item dropdown">

                <Dropdown.Toggle className="nav-link" id="dropdown-basic">
                  <div className='__avtar'>
                    <img src={isAvatar ? isAvatar : Avtar} alt="avtar" />
                  </div>

                  <div className='__data'>
                    <h5>{userDetails?.username ? userDetails?.username : "Loading..."} </h5>
                  </div>
                </Dropdown.Toggle>

                <div className='__drop-outer'>
                  <div className='__dropdown'>
                    <Link className="dropdown-item" to="/profile"> <span><ManageAccounts /></span> {t("Profile")}</Link>
                    {/* <Link className="dropdown-item" to="/update-wallet"> <span><TipsAndUpdates /></span> {t("Update Wallet Address")}</Link> */}
                    {/* <Link className="dropdown-item" to="/walletHistory"> <span><AutoGraph /></span> {t("Wallet Activities")}</Link> */}
                    <Link className="dropdown-item bg-danger logout" onClick={LogOut}> <span><Logout /></span> {t("Logout")}</Link>
                  </div>
                </div>
              </div>

              <button type='button' className='toggleButton' onClick={() => handleButtonClick()}>
                <span className='--menu'><Reorder /></span>
                <span className='--close'> <Close/> </span>                
              </button>

            </div>




          </div>

        </div>

      </div>



    </>
  )
}

export default Header