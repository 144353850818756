import { createContext, useContext, useState } from "react";

const Web3ContextProvider = ({ children }) => {
  
  const [WalletAddress, setWalletAddress] = useState(null);
  const [web3data, setweb3data] = useState({});


  
  return (
    <Web3context.Provider
      value={{
        setWalletAddress,
        WalletAddress,
        web3data,
        setweb3data
      }}
    >
      {children}
    </Web3context.Provider>
  );
};

export default Web3ContextProvider;

export const Web3context = createContext();

export const useWeb3Con = () => useContext(Web3context);
