import React, { useEffect, useState } from 'react'
import { api_url, titleDescription } from '../../config/Config';
import uploadIcon from '../../Assets/Image/uploadIcon.png';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { useTranslation } from 'react-i18next';
import Loader from '../../config/Loader';
import { Bookmark, FullscreenSharp } from '@mui/icons-material';
import Modal from 'react-responsive-modal';
import SupportTab from '../../Private/SupportTab';


const Support = (props: any) => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    });

    const [selectedOption, setSelectedOption] = useState('');
    const [description, setDescription] = useState('');
    // const [image, setImage] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [uploadImage, setUploadImage] = useState('');

    const handleImageChange = (e: any) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Image = reader.result as string;;
                setUploadImage(base64Image);
            };

            reader.readAsDataURL(selectedImage);
        }
    };
    const [btnDisable, setbtnDisable] = useState(false);
    const SubmitTicket = async () => {
        setbtnDisable(true);
        // let formData = new FormData();
        let data = {
            "subject": selectedOption,
            "description": description,
            "image": uploadImage
        }

        let res = await _fetch(`${api_url}support/support-ticket`, "POST", data, {})
        if (res?.status === "success") {
            toasted.success(res?.message);
            setbtnDisable(false);

        } else {
            setbtnDisable(false);
            toasted.error(res?.message);
        }
    }

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    return (
        <main>

            <SupportTab links={[{ name: 'create ticket', path: '/create-ticket' }, { name: 'ticket history', path: '/ticket-history' },{ name : 'Presentations', path: '/presentations'}]} />

            <div className="support_main">
                <div className="support_inner mb-4">
                    <div className='card-header'>
                        <p> {t("Support")} services 24x7 Live</p>
                    </div>

                    <div className="support-section">

                        <div className="select_reason mb-3">
                            <label className='form_lable' htmlFor=''> Subject</label>
                            <select className="form-control" aria-label="Default select example" onChange={(e: any) => setSelectedOption(e.target.value)} value={selectedOption} >
                                <option selected>{t("Question about node")}</option>
                                <option value="Question about income">{t("Question about income")}</option>
                                <option value="Others">{t("Others")}</option>
                            </select>
                        </div>

                        <div className='combo-sec'>
                            <div className="select_reason mb-3">
                                <label className='form_lable' htmlFor=''> {t("Description")}</label>
                                <textarea rows={7} placeholder={t("Please describe the issue you are experiencing in detail...")} className='form-control' onChange={(e: any) => setDescription(e.target.value)} value={description} />
                            </div>

                            <div className="upload__data_tk">

                                <div className='h_1323'>
                                    <label className='form_lable w-auto' htmlFor=''> {t("Upload your files here")}</label>
                                    {imagePath ?
                                        <button type='button' className='btn view-btn' onClick={() => { onOpenModal() }}> <FullscreenSharp /> Preview Image</button>
                                        :
                                        ""
                                    }
                                </div>

                                {imagePath ?
                                    <div className='main__show__images'>
                                        <img src={imagePath} alt="upload-img" />
                                    </div>
                                    :
                                    <label htmlFor="upload" className='uplodas__container'>
                                        <span>
                                            <img src={uploadIcon} className='upload_icon' alt="upload-icon" />
                                        </span>
                                        <input className='form-control' type="file" id="upload" style={{ display: "none" }} onChange={(e: any) => { handleImageChange(e); setImagePath((URL.createObjectURL(e.target.files[0]))) }} />
                                        <label className='form_lable text-danger mb-3' htmlFor="upload"> <Bookmark /> {t("The file size should not exceed 4 MB")} {t("File format")} .png, jpeg, jpg </label>
                                    </label>
                                }
                            </div>

                        </div>


                        <div className="btn-sec">
                            <button className='new_button_css_dashboard' onClick={SubmitTicket} disabled={btnDisable}>
                                {btnDisable === true &&
                                    <Loader LoaderTxt={'Please Wait...'} />
                                }
                                {btnDisable === false && <> {t("Send")}</>}
                            </button>
                        </div>

                    </div>
                </div>
            </div>


            <Modal open={open} onClose={onCloseModal} center
                classNames={{
                    modal: "show__upload_images_modal",
                }} >
                <div className='show__23'>
                    <img src={imagePath} width="100%" alt="uploads_s" />
                </div>
            </Modal>
        </main>
    )
}

export default Support