import React, { useEffect, useState } from 'react';
import { api_url, currency, title, titleDescription, website } from '../../config/Config';
import Table from 'react-bootstrap/Table';
import _fetch from '../../config/api';
import { Link, useLocation } from 'react-router-dom';
import toasted from '../../config/toast';
import { AccountBalance, AccountBalanceWallet, AddCard, ContentCopy, CopyAll, CrisisAlert, Description, MonetizationOn, SwapHorizontalCircle, SwitchAccessShortcutAdd, Wallet } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import Marquee from "react-fast-marquee";
import avtar from "../../Assets/Icon/user-icon.png";
import NoResult from './NoResult';
import CountdownTimer from './CountdownTimer'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Dashboard = (props: any) => {

  var myVariable = Date.now();
  var makeDate = new Date(myVariable);
  var prev = new Date(makeDate.getFullYear(), makeDate.getMonth() - 1);


  const { t } = useTranslation();
  let location = useLocation();

  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [sponsorId, setSponsorId] = useState('');
  const [loading, setLoading] = useState(true);
  const [fundValues, setFundValues] = useState([]);
  const [bannerData, setBannerData] = useState({ banner_status: "", description: "", banner: "" });
  const [newsData, setNewsData] = useState({ title: "", description: "", status: "" });
  const [allUserData, setAllUserData] = useState({ sponsor_id: "", level_distribution: 0, boosterIncome: "0", totalIncome: "0", avaliableIncome: "0", TotalDeposit: "0", AvaliableDeposit: { fundValue: "", reBuyValue: "" }, directIncome: "0", levelIncome: "0", poolIncome: "0", roiIncome: "0", royaltyIncome: "0", userInfo: { user_info: { name: "", income_limit: "", package: { current_package: "", previous_package: "", total_package: "" } } } });
  const [bussinessData, setBussinessData] = useState({ firstleg: 0, secondleg: 0, thirdleg: 0 });
  const [userRank, setUserRank] = useState({ rank: 0, rank_name: "" });




  const [rankArray, setRankArray] = useState([
    { "business": 2000, "total_business": 2000, "rank_name": "Jade", "rank_level": 1, "amount": 0 },
    { "business": 5000, "total_business": 7000, "rank_name": "Peral", "rank_level": 2, "amount": 200 },
    { "business": 10000, "total_business": 17000, "rank_name": "Sapphire", "rank_level": 3, "amount": 400 },
    { "business": 25000, "total_business": 42000, "rank_name": "Sapphire 25", "rank_level": 4, "amount": 1000 },
    { "business": 50000, "total_business": 92000, "rank_name": "Ruby", "rank_level": 5, "amount": 2000 },
    { "business": 100000, "total_business": 192000, "rank_name": "Emerald", "rank_level": 6, "amount": 4000 },
    { "business": 250000, "total_business": 442000, "rank_name": "Diamond", "rank_level": 7, "amount": 10000 },
    { "business": 500000, "total_business": 942000, "rank_name": "Double Diamond", "rank_level": 8, "amount": 20000 },
    { "business": 1000000, "total_business": 1942000, "rank_name": "Triple Diamond", "rank_level": 9, "amount": 40000 },
    { "business": 2000000, "total_business": 3942000, "rank_name": "Crown Diamond", "rank_level": 10, "amount": 80000 },
    { "business": 5000000, "total_business": 8942000, "rank_name": "Royal Crown Diamond", "rank_level": 11, "amount": 200000 },
    { "business": 10000000, "total_business": 18942000, "rank_name": "Vice President", "rank_level": 12, "amount": 400000 },
    { "business": 30000000, "total_business": 48942000, "rank_name": "Senior Vice President", "rank_level": 13, "amount": 1200000 },
    { "business": 50000000, "total_business": 98942000, "rank_name": "Global President", "rank_level": 14, "amount": 2000000 },
    { "business": 125000000, "total_business": 223942000, "rank_name": "Universal President", "rank_level": 15, "amount": 2500000 },
    { "business": 250000000, "total_business": 473942000, "rank_name": "Streakk President", "rank_level": 16, "amount": 3500000 },
  ]);



  const rankLevel = userRank?.rank;
  const nextRank = rankArray?.[rankLevel]?.rank_name;
  const MaxKingMaker = (rankArray?.[rankLevel]?.business) * 0.4;
  const MaxSecondLeg = (rankArray?.[rankLevel]?.business) * 0.4;
  const MaxOther = (rankArray?.[rankLevel]?.business) * 0.2;
  let PreviousMaxKingMaker = 0;
  let PreviousMaxSecondLeg = 0;
  let PreviousMaxOther = 0;
  if (rankLevel > 0) {
    const previousRank = rankLevel - 1;

    PreviousMaxKingMaker = (rankArray?.[previousRank]?.total_business) * 0.4;
    PreviousMaxSecondLeg = (rankArray?.[previousRank]?.total_business) * 0.4;
    PreviousMaxOther = (rankArray?.[previousRank]?.total_business) * 0.2;
  }

  const RankRoyality = (rankArray?.[rankLevel]?.amount);



  const copyToClipboard = (address: any, message: any) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success(message + ' copied successfull!');
  }


  const [userDetails, setuserdetails] = useState({
    business: {
      direct_business: 0,
      team_business: 0
    },
    company_distribution: 0,
    directs: {
      total: 0,
      active: 0,
      inactive: 0
    },
    income_info: {
      "referral_airdrop": 0,
      "staking_bonus": 0,
      "token_income": 0,
      "booster_airdrop": 0,
      "structure_airdrop": 0,
      "airdrop_reward": 0,
      "available_income": 0,
      "total_income": 0,
      "total_withdraw": 0,
      "total_ewallet": 0,
      "available_ewallet": 0,
      "income_limit": 0,
      "available_token": 0,
      "total_token": 0,
      "booster_dollar": 0,
      "staking_dollar": 0,
    },
    right_count: 0,
    left_count: 0,
    right_power: 0,
    left_power: 0,
    right_roi: 0,
    left_roi: 0,
    username: "",
    phone: "",
    sponsor_id: "",
    name: "",
    wallet_address: null,
    active_status: false,
    disable: false,
    withdraw_status: true,
    package: 0,
    total_package: 0,
    active_downline: 0,
    single_leg_status: 0,
    level_distribution: 0,
    activation_at: '',
    upgraded_at: '',
  });


  const userDataFound = async () => {

    const data1 = await _fetch(`${api_url}user/userInfo`, "GET", {});
    if (data1.status === 'success') {

      setuserdetails(data1?.data?.data);
      setSponsorId(data1?.data?.data?.username);
      setUserRank(data1?.data?.data?.user_info);
      setInfo(data1?.data?.data);
      setUserInfo(data1?.data?.data?.user_info);
      console.log(data1?.data?.data);

    } else {
      // localStorage.clear();
      // navigate("/login");
    }
  }
  const [tokenPrice, settokenPrice] = useState(0);

  const coinPrice = async () => {

    const data1 = await _fetch(`${api_url}activation/tokenPrice`, "GET", {});
    if (data1.status === 'success') {
      localStorage.setItem("api_called", 'false');

      settokenPrice(data1?.token_price);

    }
  }

  const [TeamUplines, setTeamUplines] = useState([]);

  const getTeamUplines = async () => {
    const data1 = await _fetch(`${api_url}report/getTeamUplines`, "GET", {});
    if (data1.status === 'success') {
      setTeamUplines(data1?.data)

    }
  }
  // const [stakedTokenDetail, setstakedTokenDetail] = useState({pendingTokens: 0, totalTokens: 0});

  // const getStakedToken = async () => {
  //   const data1 = await _fetch(`${api_url}user/stakedTokens`, "GET", {});
  //   if (data1.status === 'success') {
  //     setstakedTokenDetail(data1?.data)
  //   }
  // }
  useEffect(() => {
    const api_called = localStorage.getItem('api_called');

    // if (!api_called || api_called == 'false') {
    localStorage.setItem("api_called", 'true');
    userDataFound();
    getfundData();
    getUserData();
    getBannerData();
    getNewsData();
    getTeamUplines();
    coinPrice()
    // getStakedToken()
    // }
    // }
  }, [location.pathname]);



  const getfundData = async () => {
    let data = await _fetch(`${api_url}fund/getfund?limit=5`, "GET", {})
    if (data?.status === "success") {
      setFundValues(data?.data);
    }
  }

  const [todayProfit, setTodayProfit] = useState(0);
  const [lastMonthProfit, setlastMonthProfit] = useState(0);
  const [yesterdayProfit, setyesterdayProfit] = useState(0);
  const [currentMonthProfit, setcurrentMonthProfit] = useState(0);


  const getUserData = async () => {
    setLoading(true);
    let res = await _fetch(`${api_url}profile/dashboard`, "GET", {})

    if (res?.status === "success") {

      setAllUserData(res?.data);
      setBussinessData(res?.data?.business?.business);
      setTodayProfit(res?.data?.profitDetails?.todayProfit)
      setyesterdayProfit(res?.data?.profitDetails?.yesterdayProfit)
      setlastMonthProfit(res?.data?.profitDetails?.lastMonthProfit)
      setcurrentMonthProfit(res?.data?.profitDetails?.currentMonthProfit)
      setLoading(false);

    }
  }



  const [info, setInfo] = useState({ sponsor_id: "" });
  const [userInfo, setUserInfo] = useState({ income_earn: 0, income_limit: 0 });

  const now = userInfo?.income_earn;


  const [open3, setOpen3] = useState(false);

  const getBannerData = async () => {
    let res = await _fetch(`${api_url}profile/bannerdata`, "GET", {})
    if (res?.status === "success") {
      setOpen3(res?.data?.[0]?.user_banner?.banner_status);
      setBannerData(res?.data?.[0]?.user_banner);
    }
  }

  const getNewsData = async () => {
    let res = await _fetch(`${api_url}profile/newsData`, "GET", {})
    if (res?.status === "success") {
      setNewsData(res?.data);
    }
  }


  const closeModal3 = () => {
    setOpen3(false);
  };

  const [isDataVisible, setIsDataVisible] = useState(false);


  const singleLegPlan = [
    { level: 1, team: 4, amount: 12, total_amount: 48 },
    { level: 2, team: 16, amount: 2, total_amount: 32 },
    { level: 3, team: 64, amount: 2.4, total_amount: 1536 },
    { level: 4, team: 256, amount: 3, total_amount: 768 },
    { level: 5, team: 1024, amount: 3.6, total_amount: 3686 },
    { level: 6, team: 4096, amount: 13, total_amount: 53248 },

  ];


  const BuyNow = async (amountSend: any, level: any, username: any) => {
    setLoading(true);
    let data = {
      "amount": amountSend,
      "level": level,
      // "levelmember" : userDetails?.sponsor_id 

    }
    let res = await _fetch(`${api_url}activation/activateNow`, "POST", data)
    if (res?.status === 'success') {
      userDataFound();
      toasted.success(res?.message);
      setLoading(false);

    }
    else {
      toasted.error(res?.message);
      setLoading(false);
    }
  }



  // const usernameName: string = `${userDetails?.wallet_address}`;

  function maskAddress(address: any) {
    if (!address) {
      return 'Loading...'; // or some other default value or error message
    }

    const start = address.slice(0, 8); // First 10 characters including '0x'
    const end = address.slice(-8);      // Last 8 characters
    return `${start}*****${end}`;
  }

  return (
    <>



      <main>
        <div className='__dash'>
          <div className='row dash-row'>

            <div className='col-12'>
              <div className="welcome-card">
                <h5 className='mb-0'> <i>🎉</i>  Hi <span>{userDetails?.username} </span>,  Welcome back! </h5>
                <h5 className='marquee'>
                  {newsData ?
                    <Marquee className='marquuqqq'>
                      <p><b>{t(newsData?.title)}</b> {t(newsData?.description)}</p>
                    </Marquee> :
                    ''}
                </h5>
              </div>
            </div>




            <div className='col-12'>
              <div className='account-card'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='__tablesec'>

                      <div className='card-header'>
                        <p> {t('account information')}</p>
                      </div>

                      <div className='card-body'>
                        <p style={{ fontSize: "14px" }}>We’re excited to invite you to become a part of our Referral Program! Share your unique referral link with friends, family, and colleagues, and you’ll earn rewards for every new customer who signs up through your link.</p>


                        <div className="input--holder --referral">

                          <Tabs>
                            <TabList>
                              <Tab>Left Referral Link</Tab>
                              <Tab>Right Referral Link</Tab>
                            </TabList>

                            <TabPanel>
                              <div className='__tabData'>
                                <div className="input--grid">
                                  <div className="default-input --filled --ref">
                                    <input type='text' value={`${website}register/${sponsorId}/L`} disabled />
                                  </div>

                                  <button className="default-button" onClick={() => { copyToClipboard(`${website}register/${sponsorId}?L`, 'Left referral link') }}> <CopyAll/></button>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className='__tabData'>
                                <div className="input--grid">
                                  <div className="default-input --filled --ref">
                                    <input type='text' value={`${website}register/${sponsorId}/R`} disabled />
                                  </div>

                                  <button className="default-button" onClick={() => { copyToClipboard(`${website}register/${sponsorId}/R`, 'Right referral link') }}> <CopyAll/></button>
                                </div>

                              </div>
                            </TabPanel>
                          </Tabs>






                          <div className="input--grid">
                            {(userDetails?.business?.direct_business <= (userDetails?.total_package * 2)) ? <CountdownTimer direct_business={userDetails?.business?.direct_business} package={userDetails?.total_package} time={userDetails?.activation_at} /> : <h4>Booster Achived</h4>}
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    <div className='___card-info'>
                      <div className="profile-sec">
                        <div className="profile-img">
                          <img src={avtar} alt={title + 'Logo'} />
                        </div>

                        <div className='info_img'>
                          <h4 className='userName'>
                            {maskAddress(userDetails?.wallet_address)}
                            <button type='button' onClick={() => { copyToClipboard(`${userDetails.wallet_address}`, 'Wallet Address') }} className='copy-btn'> <ContentCopy /></button> </h4>
                          <h5> <span className='badge bg-success me-1' >PACKAGE: ${userDetails?.total_package > 0 ? userDetails?.total_package : userDetails?.package}</span> <span className='badge bg-success' >Live Price: ${tokenPrice}</span>  </h5>


                        </div>
                      </div>

                      <div className='__items'>
                        <div className='sposaner__name'>
                          <p>ID</p>
                          <p>{sponsorId ? <> <span>#</span>{sponsorId}</> : "Loading..."}</p>
                        </div>

                        <div className='sposaner__name'>
                          <p>sponsor ID</p>
                          <p>{info?.sponsor_id}</p>
                        </div>
                      </div>

                      <div className='sposaner__name'>
                        <p> Staked Token</p>
                        <p>{(userDetails?.income_info?.total_token / 2)} TGW</p>
                      </div>

                      <div className='sposaner__name'>
                        <p> Bonus Token</p>
                        <p>{(userDetails?.income_info?.total_token / 2)}  TGW</p>
                      </div>



                      <div className='sposaner__name'>
                        <p>Total Staked Token</p>
                        <p>{(userDetails?.income_info?.total_token)}  TGW</p>
                      </div>

                      <div className='__btnd'>
                        <Link to='/profile' className='btn'> Edit Profile</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-sm-6'>
              <div className='card vartical__card'>
                <div className='__icon'>
                  <Wallet />
                </div>

                <div className='vartical__img'>
                  <h4 className='m-0'>{userDetails?.income_info?.available_income >= 0 ? `${Number(userDetails?.income_info?.available_income).toFixed(2)}` : "0"} </h4>
                  <span>{currency}</span>
                </div>
                <div className='card-body'>
                  <h3>{t("Available Working Airdrop")}</h3>
                  <Link to={'/payout-request'} className='btn mt-2'>Withdraw</Link>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-sm-6'>
              <div className='card vartical__card'>
                <div className='__icon'>
                  <AccountBalanceWallet />
                </div>

                <div className='vartical__img'>
                  <h4 className='m-0'>  {userDetails?.income_info?.available_token >= 0 ? <> {Number(userDetails?.income_info?.available_token).toFixed(2)}  </> : "-"}  </h4>
                  <span>TGW</span>
                </div>

                <div className='card-body'>
                  <h3>{t("Available TGW Token")}</h3>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-sm-6'>
              <div className='card vartical__card'>
                <div className='__icon'>
                  <AddCard />
                </div>

                <div className='vartical__img'>

                  <h4 className='m-0'>{userDetails?.business?.direct_business >= 0 ? <> {Number(userDetails?.business?.direct_business)?.toFixed(2)}  </> : "-"} </h4>
                  <span>{currency}</span>
                </div>

                <div className='card-body'>
                  <h3>{t("Direct Business")}</h3>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-sm-6'>
              <div className='card vartical__card'>
                <div className='__icon'>
                  <AccountBalance />
                </div>

                <div className='vartical__img'>
                  <h4 className='m-0'>{userDetails?.business?.team_business >= 0 ? <> {Number(userDetails?.business?.team_business)?.toFixed(2)}  </> : "-"} </h4>
                  <span>{currency}</span>
                </div>

                <div className='card-body'>
                  <h3>{t("Team Business")}</h3>
                </div>
              </div>
            </div>



          </div>
        </div>


        <div className="main_inner_sec ">
          <div className="rank_royality">
            <div className="row rank_royality__row mb-5">

              <div className='col-12'>
                <div className='__v2_card'>
                  <div className='row'>

                    <div className='col-sm-6 col-xl-3 col-lg-4'>
                      <div className='card __v2_in_card'>
                        <div className='card-body'>
                          <CrisisAlert />
                          <h6>{t("Referral Airdrop")}</h6>
                          <h4 className='m-0'>  ${userDetails?.income_info?.referral_airdrop >= 0 ? <> {Number(userDetails?.income_info?.referral_airdrop)?.toFixed(2)}</> : "-"} </h4>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-6 col-xl-3 col-lg-4'>
                      <div className='card __v2_in_card'>
                        <div className='card-body'>
                          <SwapHorizontalCircle />
                          <h6>{t("Staking Airdrop")}</h6>
                          <h4 className='m-0'> {userDetails?.income_info?.staking_bonus >= 0 ? <> {Number(userDetails?.income_info?.staking_bonus)?.toFixed(2)}  </> : "-"}TGW </h4>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-6 col-xl-3 col-lg-4'>
                      <div className='card __v2_in_card'>
                        <div className='card-body'>
                          <SwapHorizontalCircle />
                          <h6>{t("Staking Bonus")}</h6>
                          <h4 className='m-0'> ${userDetails?.income_info?.staking_dollar >= 0 ? <> {Number(userDetails?.income_info?.staking_dollar)?.toFixed(2)}  </> : "-"} </h4>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-6 col-xl-3 col-lg-4'>
                      <div className='card __v2_in_card'>
                        <div className='card-body'>
                          <SwapHorizontalCircle />
                          <h6>{t("Booster Airdrop")}</h6>
                          <h4 className='m-0'> {userDetails?.income_info?.booster_airdrop >= 0 ? <> {Number(userDetails?.income_info?.booster_airdrop)?.toFixed(2)}  </> : "-"}TGW </h4>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-6 col-xl-3 col-lg-4'>
                      <div className='card __v2_in_card'>
                        <div className='card-body'>
                          <SwapHorizontalCircle />
                          <h6>{t("Booster Bonus")}</h6>
                          <h4 className='m-0'> ${userDetails?.income_info?.booster_dollar >= 0 ? <> {Number(userDetails?.income_info?.booster_dollar)?.toFixed(2)}  </> : "-"} </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12'>
                <div className='_v1_row row '>

                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Available")} Airdrop</h5>
                          <h4> {userDetails?.income_info?.total_income >= 0 ? `${Number(userDetails?.income_info?.total_income).toFixed(2)}` : "0"} USDT </h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Structure Airdrop")} Total</h5>
                          <h4> {userDetails?.income_info?.structure_airdrop >= 0 ? `${Number(userDetails?.income_info?.structure_airdrop).toFixed(2)}` : "0"} USDT </h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Airdrop Reward")} USDT</h5>
                          <h4> {userDetails?.income_info?.airdrop_reward >= 0 ? `${Number(userDetails?.income_info?.airdrop_reward).toFixed(2)}` : "0"} USDT </h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Available Airdrop")} USDT</h5>
                          <h4> {userDetails?.income_info?.available_income >= 0 ? `${Number(userDetails?.income_info?.available_income).toFixed(2)}` : "0"} USDT </h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div> */}



                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Total Airdrop")} </h5>
                          <h4> {userDetails?.income_info?.total_income >= 0 ? `${Number(userDetails?.income_info?.total_income).toFixed(2)}` : "0"} USDT </h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Working Airdrop Limit")} </h5>
                          <h4> {userDetails?.income_info?.income_limit >= 0 ? `${Number(userDetails?.income_info?.income_limit).toFixed(2)}` : "0"} USDT </h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Total Withdrawal")}</h5>
                          <h4> {userDetails?.income_info?.total_withdraw >= 0 ? <> {Number(userDetails?.income_info?.total_withdraw)?.toFixed(2)}  </> : '-'} USDT</h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Total Ewallet")}</h5>
                          <h4> {userDetails?.income_info?.total_ewallet >= 0 ? <> {Number(userDetails?.income_info?.total_ewallet)?.toFixed(2)}  </> : '-'} </h4>
                        </div>

                        <div className='right_v1'>
                          <p><MonetizationOn /></p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Direct Business")}</h5>
                          <h4> {userDetails?.business?.direct_business >= 0 ? <> {Number(userDetails?.business?.direct_business)?.toFixed(2)}  </> : "-"} </h4>
                        </div>

                        <div className='right_v1'>
                          <p><Wallet /></p>
                        </div>
                      </div>
                    </div>
                  </div> */}


                  {/* <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Team Business")}</h5>
                          <h4> {userDetails?.business?.team_business >= 0 ? <> {Number(userDetails?.business?.team_business)?.toFixed(2)}  </> : "-"}</h4>
                        </div>

                        <div className='right_v1'>
                          <p><Wallet /></p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Active Downline")}</h5>
                          <h4> {userDetails?.active_downline >= 0 ? <> {Number(userDetails?.active_downline)}  </> : "-"}</h4>
                        </div>

                        <div className='right_v1'>
                          <p><Wallet /></p>
                        </div>
                      </div>
                    </div>
                  </div> */}


                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Directs")}</h5>
                          <h4> {userDetails?.directs?.active >= 0 ? <> {Number(userDetails?.directs?.active)}</> : "-"}</h4>
                        </div>

                        <div className='right_v1'>
                          <p><SwitchAccessShortcutAdd /></p>
                        </div>
                      </div>
                    </div>
                  </div>




                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("L Business | R Business")}</h5>
                          <h4> ${userDetails?.left_power >= 0 ? <> {Number(userDetails?.left_power)?.toFixed(2)}</> : "-"} | ${userDetails?.right_power >= 0 ? <> {Number(userDetails?.right_power)?.toFixed(2)}</> : "-"}</h4>
                        </div>

                        <div className='right_v1'>
                          <p><SwitchAccessShortcutAdd /></p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("L Staking | R Staking")}</h5>
                          <h4> {userDetails?.left_roi >= 0 ? <> {Number(userDetails?.left_roi)?.toFixed(2)}</> : "-"}TGW | {userDetails?.right_roi >= 0 ? <> {Number(userDetails?.right_roi)?.toFixed(2)}</> : "-"}TGW</h4>
                        </div>

                        <div className='right_v1'>
                          <p><SwitchAccessShortcutAdd /></p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("L Downline | R Downline")}</h5>
                          <h4> {userDetails?.left_count >= 0 ? <> {Number(userDetails?.left_count)}</> : "-"} | {userDetails?.right_count >= 0 ? <> {Number(userDetails?.right_count)}</> : "-"}</h4>
                        </div>

                        <div className='right_v1'>
                          <p><SwitchAccessShortcutAdd /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Staked Token")}</h5>
                          <h4 className='m-0'> {Number((userDetails?.income_info?.total_token / 2))?.toFixed(2)} TGW</h4>
                        </div>

                        <div className='right_v1'>
                          <p><SwitchAccessShortcutAdd /></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-6 col-xl-3 col-lg-4'>
                    <div className='__v1_card card '>
                      <div className='card-body'>
                        <div className='__left_v1'>
                          <h5>{t("Pending Staked Token")}</h5>
                          <h4 className='m-0'> {Number(userDetails?.income_info?.available_token)?.toFixed(2)} TGW</h4>
                        </div>

                        <div className='right_v1'>
                          <p><SwitchAccessShortcutAdd /></p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>



              <div className='col-lg-12 col-md-12 d-none'>
                <div className='card tk-trading__card mb-5'>

                  <div className='card-header'>
                    <p> {title} Team Presentation</p>
                  </div>

                  <div className="table_responsive">
                    <Table className="support_table all_tables">
                      <thead className='thead_dashboard'>
                        <tr className='text-center'>

                          <th> {t("Level")}</th>
                          <th>{t("Username")}</th>
                          <th>{t("Amount")}</th>
                          <th>{t("Name")}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className='tbody_dashboard'>
                        {TeamUplines.length == 0 ? <tr className='no_data_tr'>
                          <td colSpan={7}>
                            <NoResult />
                          </td>
                        </tr> :
                          <>
                            {TeamUplines?.map((item: any, index: any) => {
                              return (
                                <>
                                  <tr className="table_data_row text-center">

                                    <td className="table_data">{item?.level}</td>
                                    <td className="table_data">{item?.username}</td>
                                    <td className="table_data">${item?.amount}</td>
                                    <td className="table_data">{(item?.name)}</td>
                                    <td>{(index + 1) < 7 ? userDetails?.level_distribution >= (index + 1) ? <button className='btn btn-sm btn-success'>Confirmed</button> : <button className='btn btn-sm btn-info' onClick={() => { BuyNow(item?.amount, (index + 1), item?.username) }}>Confirm</button> : userDetails?.company_distribution == 1 ? <button className='btn btn-sm btn-success'>Confirmed</button> : <button className='btn btn-sm btn-info' onClick={() => { BuyNow(item?.amount, (index + 1), item?.username) }}>Confirm</button>}</td>

                                  </tr>
                                </>
                              )
                            })}
                          </>}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

              {/* <div className='col-lg-6 col-md-12'>
                <div className='card tk-trading__card'>

                  <div className='card-header'>
                    <p> {title} Plan Presentation</p>
                  </div>

                  <div className="table_responsive">
                    <Table className="support_table all_tables">
                      <thead className='thead_dashboard'>
                        <tr className='text-center'>

                          <th> {t("Level")}</th>
                          <th>{t("Team")}</th>
                          <th>{t("Amount")}</th>
                          <th>{t("Total Amount")}</th>
                        </tr>
                      </thead>
                      <tbody className='tbody_dashboard'>
                        {singleLegPlan.length == 0 ? <tr className='no_data_tr'>
                          <td colSpan={4}>
                            <div className='no_result_found_sec'>
                              <p> <Description /> <br /> {t("Data not found")}!</p>
                            </div>
                          </td>
                        </tr> :
                          <>
                            {singleLegPlan?.map((item: any, index: any) => {
                              return (
                                <>
                                  <tr className="table_data_row text-center">

                                    <td className="table_data">{item?.level}</td>
                                    <td className="table_data">{item?.team}</td>
                                    <td className="table_data">${item?.amount}</td>
                                    <td className="table_data">${(item?.team * item?.amount)?.toFixed(2)}</td>

                                  </tr>
                                </>
                              )
                            })}
                          </>}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div> */}
            </div>
          </div>






        </div>
      </main>

      {/* {open3 && (
        {bannerData?.banner_status === true ? (
        <Modal open={open3} onClose={closeModal3} center
          classNames={{
            modal: "homepage_modal",
          }}>
          <div className="banner_modal">
            <img src={bannerData?.banner} alt={bannerData?.description} className='banner_img' />
            <h6 className='modal-heading'>{bannerData?.description}</h6>
          </div>
        </Modal>
         ) : (
          null  You can replace this with any other content or leave it as an empty string
        )}
      )}

      {
        (show) ?
          newsData?.status &&

          <Alert className='welcome__alert' onClose={() => setShow(false)} dismissible>
            <Alert.Heading><VolumeUpRounded />  {t(newsData?.title)}</Alert.Heading>
            <p className='mb-0'> {t(newsData?.description)} </p>
          </Alert>
          :
          ""
      } */}

    </>
  )
}

export default Dashboard