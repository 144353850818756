import React from 'react';
import './Assets/Scss/Style.scss';
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";
import Register from './Public/Users/Register';
import Forgot from './Public/Users/Forgot';
import Dashboard from './Public/HomePage/Dashboard';
import Profile from './Public/HomePage/Profile';
import Team from './Public/HomePage/Team';
import Ledger from './Public/HomePage/Ledger';
import LedgerNon from './Public/HomePage/LedgerNon';

import PayHistory from './Public/HomePage/PayHistory';
import NonWihdrawHIstory from './Public/HomePage/NonWihdrawHIstory';

import Footer from './Layout/Footer';
import Sidebar from './Layout/Sidebar';
import PrivateRoutes from './config/PrivateRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Presentations from './Public/HomePage/Presentations';
import Support from './Public/HomePage/Support';
import Demo from './Public/Demo';
import Index from './Public/index_page/Index';
import TicketView from './Public/HomePage/TicketView';
import PayoutRequest from './Public/HomePage/PayoutRequest';
import PayoutRequestNonWorking from './Public/HomePage/PayoutRequestNonWorking';

import DirectIncome from './Public/HomePage/directIncome';
import { LanguageProvider } from "../src/LanguageContext";
import MainOverview from './Public/HomePage/MainOverview';
import WaitingScreen from './Public/WaitingScreen';
import ApprovedScreen from './Public/ApprovedScreen';
import Forgotconformation from './Public/Forgotconformation';
import AdminLogin from './Public/Users/AdminLogin';
import HomeHeader from './Public/HomeHeader';
import Header from './Layout/Header';
import HomeFooter from './Public/HomeFooter';
import Success from './Public/Users/Success';
import ComingSoon from './Public/ComingSoon';
import AdminUserLogin from './Public/Users/AdminUserLogin';
import WalletHistory from './Public/HomePage/WalletHistory';
import UpdateWalletAddress from './Public/HomePage/UpdateWalletAddress';
import Token from './Public/index_page/Token';
import ScrollTop from './Public/index_page/ScrollTop';
import About from './Public/About';
import Roadmap from './Public/Roadmap';
import HeaderNew from './Public/HeaderNew';
import UpgradePlan from './Public/HomePage/UpgradePlan';
import WalletLedger from './Public/HomePage/WalletLedger';
import ActivationHistory from './Public/HomePage/ActivationHistory';
import DownlineReport from './Public/HomePage/DownlineReport';
import SocialMedia from './Public/HomePage/SocialMedia';
import BinaryTree from './Public/HomePage/Component/BinaryTree';
import Login from './Public/Users/Login';

function App() {

  // ? Login page.....
  function LoginLayout() {
    return (
      <div className='__home-yt logs-page '>
        <HeaderNew />
        <Outlet />
        <HomeFooter />
      </div>
    );
  }

  // ? Login page.....
  function VerificationLayout() {
    return (
      <>
        <Outlet />
        {/* <PublicBottomBar/> */}
      </>
    );
  }

  function PublicOuter() {
    return (
      <div className='__home-yt'>
        <HomeHeader />
        <Outlet />
        <HomeFooter />
      </div>
    );
  }

  // Public page.....
  function PublicLayout() {
    return (
      <>
        <Sidebar />
        <Header />
        <Outlet />
        <Footer />

      </>
    );
  }

  // Only Private page.....
  function PrivateLayout() {
    return (
      <>
        <LanguageProvider>
          <Sidebar />
          <Header />
          <Outlet />
          <Footer />
        </LanguageProvider>
      </>
    );
  }

  return (
    <>
      <Router>
        <ScrollTop />
        <LanguageProvider>
          <Routes>

            <Route element={<PublicOuter />}>
              {/* <Route path='*' element={<ComingSoon />} /> */}
              <Route path='*' element={<Index />} />
              <Route path='/' element={<Index />} />
              <Route path='/token' element={<Token />} />
              <Route path='/about' element={<About />} />
              <Route path='/roadmap' element={<Roadmap />} />

              {/* <Route path='/privacy-policy' element={<PrivacyPolicy />} /> */}
              {/* <Route path='/terms-conditions' element={<Terms />} /> */}
              {/* <Route path='/contact-us' element={<ContactUs />} /> */}

            </Route>

            <Route element={<VerificationLayout />}>
              <Route path='/successfull' element={<Success />} />
              <Route path='/waiting-conformation' element={<WaitingScreen />} />
              <Route path='/verification/:token' element={<ApprovedScreen />} />
              <Route path='/resetPassword/:token' element={<Forgotconformation />} />
              <Route path='/admin-login/:email/:pass' element={<AdminUserLogin />} />

            </Route>

            <Route element={<LoginLayout />}>
              <Route path='/login' element={<Login pageTitle={`Login`} />} />
              <Route path='/admin-login' element={<AdminLogin pageTitle={`Admin Login`} />} />
              <Route path='/register' element={<Register pageTitle={`Register`} />} />
              <Route path='/register/:sponser' element={<Register pageTitle={`Register`} />} />
              <Route path='/register/:sponser/:userPosition' element={<Register pageTitle={`Register`} />} />
              <Route path='/forgot' element={<Forgot pageTitle={`Forgot`} />} />
              <Route path='/demo' element={<Demo />} />
            </Route>

            {/* All Public pages___ */}
            <Route element={<PrivateRoutes />}>

              <Route element={<PublicLayout />}>
                <Route path='/dashboard' element={<Dashboard pageTitle={`Dashboard`} />} />
                <Route path='/profile' element={<Profile pageTitle={`Profile`} />} />
                {/* <Route path='/update-wallet' element={<UpdateWalletAddress pageTitle={`Update Wallet Address`} />} /> */}
                {/* <Route path='/walletHistory' element={<WalletHistory pageTitle={`Wallet History`} />} /> */}
                <Route path='/main-overview' element={<MainOverview pageTitle={`Overview`} />} />
                <Route path='/team' element={<Team pageTitle={`Team`} />} />
                <Route path='/report/:type' element={<DirectIncome pageTitle={`Direct Income`} />} />
                <Route path='/payout-request' element={<PayoutRequest pageTitle={`Payout Request`} />} />
                <Route path='/pay-history' element={<PayHistory pageTitle={`Pay History`} />} />
                <Route path='/presentations' element={<Presentations pageTitle={`Presentations`} />} />
                <Route path='/ledger' element={<Ledger pageTitle={`Ledger`} />} />
                <Route path='/non-ledger' element={<LedgerNon pageTitle={`Ledger`} />} />
                {/* <Route path='/wallet-ledger' element={<WalletLedger pageTitle={`Ledger`} />} /> */}
                <Route path='/activation-ledger' element={<ActivationHistory pageTitle={`Activation Ledger`} />} />
                <Route path='/downline-report/:type' element={<DownlineReport pageTitle={`Ledger`} />} />
                <Route path='/non-working-request' element={<PayoutRequestNonWorking pageTitle={`Non Working Payout Request`} />} />
                <Route path='/non-pay-history' element={<NonWihdrawHIstory pageTitle={`Pay History`} />} />
                <Route path='/create-ticket' element={<Support pageTitle={`Support`} />} />
                <Route path='/ticket-history' element={<TicketView pageTitle={`Ticket View`} />} />
                <Route path='/upgrade-plan' element={<UpgradePlan pageTitle={`Upgrade`} />} />
                <Route path='/socialmedia' element={<SocialMedia pageTitle={`Social Media`} />} />
                <Route path='/team-2' element={<BinaryTree pageTitle={`Team`} />} />





                {/* not use page.. */}

                {/* <Route path='/trading-profit' element={<Trading pageTitle={`Buy History`} />} /> */}
                {/* <Route path='/buy-infra' element={<BuyInfra pageTitle={`Buy Infra`} />} /> */}
                {/* <Route path='/transfer-fund' element={<TransFundHistory pageTitle={`Trasfer Fund History`} />} /> */}
                {/* <Route path='/deposit' element={<Deposit pageTitle={`Deposit`} />} /> */}
                {/* <Route path='/depositHistory' element={<DepositHistory pageTitle={`Deposit History`} />} /> */}
                {/* <Route path='/wallet-ledger' element={<EWalletHistory pageTitle={`Wallet Ledger`} />} /> */}
                {/* <Route path='/all_reports' element={<AllReports pageTitle={`All Reports`} />} /> */}
                {/* <Route path='/fund' element={<Fund pageTitle={`Fund`} />} /> */}
                {/* <Route path='/withdraw' element={<WIthdraw pageTitle={`Withdraw`} />} /> */}


              </Route>
            </Route>



          </Routes>
        </LanguageProvider>
      </Router >

      <ToastContainer />
    </>
  );
}

export default App;
