import React, { useEffect } from 'react'

import { title, titleDescription } from '../../config/Config';
import Banner from './Component/Banner';
import AboutCom from './Component/AboutCom';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Howit from './Component/Howit';
import RoadMapCom from './Component/RoadMapCom';
import { Link } from 'react-router-dom';
import leavesSingle_img from '../../Assets/new-theme/leaves-single.png';


const Index = () => {
      useEffect(() => {
            document.title = `Home | ${titleDescription}`;
      });

      const auth = localStorage.getItem('auth');

      return (
            <>
                  <ParallaxProvider>
                        <Banner />

                        <Parallax speed={-15 * 5}>
                              {/* <div className='move-section'>
                                    <img src={leavesSingle_img} alt='icon' />
                              </div> */}

                              <div className='move-section-right'>
                                    <img src={leavesSingle_img} alt='icon' />
                              </div>
                        </Parallax>

                        <AboutCom />
                        <Howit />
                        <RoadMapCom />

                        <div className="Virtual_Assets_sec">
                              <div className="container-index">
                                    <div className="inner">
                                          <img src={leavesSingle_img} alt='icon' width="150px" />
                                          <h1>Contact Us</h1>
                                          <p>For more information, visit our website at {title}. Join us in our mission to create a greener, more sustainable world through the power of blockchain technology.</p>
                                          {
                                                auth ?
                                                      <Link to='/dashboard' className='button_style mt-2'> Get Start</Link>
                                                      :
                                                      <div className="input_field">
                                                            <input type="text" placeholder='Telegram' />
                                                            <Link to='#'>Join Telegram</Link>
                                                      </div>
                                          }
                                    </div>
                              </div>
                        </div>

                  </ParallaxProvider>
            </>
      )
}

export default Index
