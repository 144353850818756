import { RocketLaunch } from '@mui/icons-material';
import React from 'react';
import { title } from '../../../config/Config';
import ROADMAPDETAILS from '../../staticData/roadmapdata';
import leavesSingle_img from '../../../Assets/new-theme/leaves-single.png';


const RoadMapCom = () => {


     // const listRef = useRef<HTMLUListElement>(null);

     // const handleScroll = (event: React.WheelEvent) => {
     //      if (listRef.current) {
     //           // Vertical scroll ko horizontal scroll me convert kar rahe hain
     //           listRef.current.scrollLeft += event.deltaY;
     //      }
     // };



     return (
          <section className='roadmap-section'>
               <div className='__roadmpa-animatoin'> <img src={leavesSingle_img} alt='icon' /> </div>

               <div className='__heading_zs'>
                    <h1> {title} Road Map</h1>
                    <p>Empowering eco-friendly solutions through blockchain for a sustainable future</p>

                    {/* <Link to='/roadmap' className="btn"> Seen More</Link> */}
               </div>

               <div className='__inout'>
                    <div className='__roadmaop-in'>
                         {ROADMAPDETAILS.map((item, index) => (
                              <div className='__f1' key={index}>
                                   <div className='__icon-roadmap'>
                                        {item.icon}
                                   </div>

                                   <div className='__descreptions'>
                                        <h4 className='year'>{item.year}</h4>
                                        <p className='title'>{item.title}</p>
                                        <p className='event-date'>{item.para}</p>
                                   </div>
                              </div>
                         ))}
                    </div>

               </div>



          </section>
     );
}

export default RoadMapCom;
