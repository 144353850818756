import React, { useEffect, useState } from "react";
import { api_url, incomesList, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import NoResult from "./NoResult";

const LedgerNon = (props: any) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${props.pageTitle} | ${titleDescription}`;
  });

  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [allIncomes, setAllIncomes] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [incomeChange, setIncomeChange] = useState('');

  useEffect(() => {
    getAllIncomeReport();
  }, [incomeChange, page, totalRecord]);


  const getAllIncomeReport = async () => {
    setLoading(true);
    if (incomeChange) {
      let res = await _fetch(`${api_url}report/all-incomes-2?type=${incomeChange}&limit=10&page=${page}`, "GET", {})
      if (res?.status === "success") {
        setLoading(false);
        setAllIncomes(res?.data?.data);
        setTotalRecord(res?.total);
      }
    }
    else {
      let res = await _fetch(`${api_url}report/all-incomes-2?limit=10&page=${page}`, "GET", {})

      if (res?.status === "success") {
        setLoading(false);
        setAllIncomes(res?.data?.data);
        setTotalRecord(res?.total);
      }
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };
  return (
    <main>

<div className="uni-level-outer">
          <div className="level_inner common_card_css loader_main">
            <div className='sub-header-kt sub-header-flex'>
              <div className="led-selected">
                <h4>Non Working Wallet Ledger</h4>
                <form className="ledger-form">
                  {/* <label className="form_lable"> Select Type</label> */}
                  <select className="form-control ledger" onChange={(e: any) => { setIncomeChange(e.target.value); setpage(1) }}>
                    <option value="">All Select Type</option>
                    {/* {incomesList?.map((income: any) => {
                      return (
                    <option value={income?.type}>{t(income?.name)}</option>

                          );
                    })} */}
                    <option value="staking_bonus">{t("Staking Bonus")}</option>
                    <option value="booster_airdrop">{t("Booster Airdrop")}</option>

                    <option value="withdraw_request">{t("Withdraw")}</option>
                  </select>
                </form>
              </div>
            </div>
            <div className="table_section mt-4">
              <div className="scrolling_table">
                <div className="scroll_under">
                  <div className="table_responsive">
                    <Table className="support_table all_tables">
                      <thead className='thead_dashboard'>
                        <tr className="table_heading_row">
                          <th>{t("Date")}</th>
                          <th>{t("Amount")}</th>
                          <th>{t("Category")}</th>
                          <th>{t("Level")}</th>
                          
                          <th>{t("Description")}</th>
                        </tr>
                      </thead>
                      <tbody className='tbody_dashboard'>
                        {allIncomes?.length == 0 ? <tr className='no_data_tr'>
                          <td colSpan={6}>
                            <NoResult />
                          </td>
                        </tr> :

                          <>
                            {allIncomes?.map((item: any, index: any) => {
                              return (
                                <>

                                  <tr className="table_data_row" key={index}>
                                    <td className="table_data">
                                      <div className="pic_text">{dateFormat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</div>
                                    </td>
                                    <td className="table_data"> <span className={item?.dollar < 0 ?'text-danger':'text-success'}>${item?.dollar?.toFixed(2)}</span></td>
                                    <td className="table_data payment_fee"> {item?.type ? item.type.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</td>
                                    <td className="table_data">
                                      {item?.level}
                                    </td>
                                    <td className="table_data">
                                      {item?.description}
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                          </>
                        }

                      </tbody>
                    </Table>
                    {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={1}
                      pageCount={totalRecord / 10}
                      previousLabel="<"
                    />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </main>
  );
};

export default LedgerNon;
