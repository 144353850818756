import { ArrowDropDownCircle, East, Info, West } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { title, titleDescription } from '../../../config/Config';
import __activePerson from '../../../Assets/new-theme/active.png';
import __inactivePerson from '../../../Assets/new-theme/inactive.png';
import __addNew from '../../../Assets/new-theme/add-new.png';
import { Link } from 'react-router-dom';

const BinaryTree = (props: any) => {

    const [accordion, setAccordion] = useState(false);


    useEffect(() => {
        document.title = `${props.pageTitle} | ${titleDescription}`;
    }, []);

    return (
        <div className='binarytree-sec'>
            <div className='row'>
                <div className='col-12'>
                    {/* <div className=__heading_sa `> */}
                    <div className={accordion ? '__heading_sa show' : '__heading_sa'}>
                        <div className='__top-heading'>
                            <p> Binary Team Structure</p>

                            <form>
                                <select className="form-select">
                                    <option>Search Type</option>
                                    <option>Search By Username</option>
                                    <option>Search By Name</option>
                                </select>

                                <div className="input-group">
                                    <input type="search" className="form-control" placeholder="Search" />
                                    {/* <button className="btn btn-success" type="submit">Go</button> */}
                                </div>

                                <button type='button' onClick={() => setAccordion(!accordion)} className='btn'> <ArrowDropDownCircle /></button>
                            </form>
                        </div>

                        <div className='__bottom-heading'>
                            <div className='details-1'>
                                <West/>
                                <p>Left Total Team : <span>0</span></p>
                                <p>Left ZS : <span>0</span>	</p>
                                <p>Left Business : <span>0</span></p>
                            </div>

                            <div className='details-1'>
                                <East/>
                                <p>Right Total Team :<span>0</span></p>
                                <p>Right ZS : <span>0</span>	</p>
                                <p>Right Business : <span>0</span></p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <section className="management-tree">
                        <div className="mgt-container">
                            <div className="mgt-wrapper">

                                <div className="mgt-item">

                                    <div className="mgt-item-parent">

                                        <div className="person">
                                            <img src={__activePerson} alt='icon' />
                                            <p className="name admin-name">Admin <br /> {title}  </p>

                                            <div className='__drophover'>
                                                <ul>
                                                    <li> <p>Username: Admin</p> </li>
                                                    <li> <p> Package: 5000</p> </li>
                                                    <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                    <li>
                                                        <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                    </li>
                                                    <li>
                                                        <p> Left points : 36850 | Right points : 0 </p>
                                                    </li>
                                                    <li>
                                                        <p>Left Business : 36850 | Right Business : 0</p>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="mgt-item-children">

                                        <div className="mgt-item-child">
                                            <div className="mgt-item">

                                                <div className="mgt-item-parent">
                                                    <div className="person activePerson">
                                                        <img src={__activePerson} alt="icon" />
                                                        <p className="name">234234 <br /> John Deo</p>

                                                        <div className='__drophover'>
                                                            <ul>
                                                                <li> <p>Username: Admin</p> </li>
                                                                <li> <p> Package: 5000</p> </li>
                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                <li>
                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="mgt-item-children">

                                                    <div className="mgt-item-child">

                                                        <div className='mgt-item-parent'>
                                                            <div className="person">
                                                                <img src={__activePerson} alt="icon" />
                                                                <p className="name">2348798 <br /> {title}</p>

                                                                <div className='__drophover'>
                                                                    <ul>
                                                                        <li> <p>Username: Admin</p> </li>
                                                                        <li> <p> Package: 5000</p> </li>
                                                                        <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                        <li>
                                                                            <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p> Left points : 36850 | Right points : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Left Business : 36850 | Right Business : 0</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mgt-item-children">

                                                            <div className="mgt-item-child">
                                                                <div className="person">
                                                                    <img src={__inactivePerson} alt="icon" />
                                                                    <p className="name">2348798 <br /> {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mgt-item-child">
                                                                <div className="person">
                                                                    <img src={__activePerson} alt="icon" />
                                                                    <p className="name">2348798 <br /> {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="mgt-item-child">
                                                        <div className="mgt-item">

                                                            <div className="mgt-item-parent">
                                                                <div className="person">
                                                                    <img src={__activePerson} alt="icon" />
                                                                    <p className="name">23489222 <br /> {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mgt-item-children">

                                                                <div className="mgt-item-child">
                                                                    <div className="person">
                                                                        <img src={__inactivePerson} alt="icon" />
                                                                        <p className="name">234234 <br /> {title}</p>

                                                                        <div className='__drophover'>
                                                                            <ul>
                                                                                <li> <p>Username: Admin</p> </li>
                                                                                <li> <p> Package: 5000</p> </li>
                                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                                <li>
                                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                                </li>
                                                                                <li>
                                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mgt-item-child">
                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to="#" className="btn"> Add New</Link>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>


                                        <div className="mgt-item-child">
                                            <div className="mgt-item">

                                                <div className="mgt-item-parent">
                                                    <div className="person">
                                                        <img src={__inactivePerson} alt="" />
                                                        <p className="name"> 892349234 <br /> {title} </p>

                                                        <div className='__drophover'>
                                                            <ul>
                                                                <li> <p>Username: Admin</p> </li>
                                                                <li> <p> Package: 5000</p> </li>
                                                                <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                <li>
                                                                    <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p> Left points : 36850 | Right points : 0 </p>
                                                                </li>
                                                                <li>
                                                                    <p>Left Business : 36850 | Right Business : 0</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mgt-item-children">

                                                    <div className="mgt-item-child">

                                                        <div className='mgt-item-parent'>
                                                            <div className="person">
                                                                <img src={__activePerson} alt="icon" />
                                                                <p className="name">nichue <br /> {title}</p>

                                                                <div className='__drophover'>
                                                                    <ul>
                                                                        <li> <p>Username: Admin</p> </li>
                                                                        <li> <p> Package: 5000</p> </li>
                                                                        <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                        <li>
                                                                            <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p> Left points : 36850 | Right points : 0 </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>Left Business : 36850 | Right Business : 0</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="mgt-item-children">

                                                            <div className="mgt-item-child">
                                                                <div className="person">
                                                                    <img src={__inactivePerson} alt="icon" />
                                                                    <p className="name">234234 <br /> {title}</p>

                                                                    <div className='__drophover'>
                                                                        <ul>
                                                                            <li> <p>Username: Admin</p> </li>
                                                                            <li> <p> Package: 5000</p> </li>
                                                                            <li> <p>Left team : 314 || Right team : 0</p> </li>
                                                                            <li>
                                                                                <p>Left Green Team : 69 | Right Green Team : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p> Left points : 36850 | Right points : 0 </p>
                                                                            </li>
                                                                            <li>
                                                                                <p>Left Business : 36850 | Right Business : 0</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mgt-item-child">
                                                                <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to="#" className="btn"> Add New</Link>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="mgt-item-child">
                                                        <div className="mgt-item">

                                                            <div className="mgt-item-parent">
                                                                <div className="person add-new">
                                                                    <img src={__addNew} alt="icon" />

                                                                    <Link to="#" className="btn"> Add New</Link>
                                                                </div>
                                                            </div>

                                                            <div className="mgt-item-children">

                                                                <div className="mgt-item-child">
                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to="#" className="btn"> Add New</Link>
                                                                    </div>
                                                                </div>

                                                                <div className="mgt-item-child">
                                                                    <div className="person add-new">
                                                                        <img src={__addNew} alt="icon" />

                                                                        <Link to="#" className="btn"> Add New</Link>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>



        </div>
    );
}

export default BinaryTree;
