import React, { useEffect, useState } from "react";
import { api_url, incomesList, titleDescription } from "../../config/Config";
import { Table } from "react-bootstrap";
import _fetch from "../../config/api";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import { useTranslation } from 'react-i18next';
import NoResult from "./NoResult";
import { useParams } from "react-router-dom";

const DownlineReport = (props: any) => {
  const { t } = useTranslation();

  const { type } = useParams();
  const [loading, setLoading] = useState(true);
  const [page, setpage] = useState(1);
  const [allIncomes, setAllIncomes] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [incomeChange, setIncomeChange] = useState(null);

  useEffect(() => {
    getAllIncomeReport();
    document.title = `${props.pageTitle} | ${titleDescription}`;

  }, [incomeChange, page, totalRecord,type]);


  const getAllIncomeReport = async () => {
    setLoading(true);
    if (incomeChange) {
      // var searchVal = '&search=level&value=' + incomeChange;
      var searchVal = '&level=' + incomeChange;

    } else {
      var searchVal = '';

    }

    let res = await _fetch(`${api_url}report/downlineReport?position=${type}&limit=10&page=${page}${searchVal}`, "GET", {})

    if (res?.status === "success") {
      setLoading(false);
      setAllIncomes(res?.data?.data);
      setTotalRecord(res?.total);
    }

  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setpage(newOffset);
  };


  return (
    <main>

      <div className="uni-level-outer">
        <div className="level_inner common_card_css loader_main">
          <div className='sub-header-kt sub-header-flex'>
            <div className="led-selected">
              <h4>{type == 'R' && 'Right'}{type == 'L' && 'Left'} Structure Report</h4>
              <form className="ledger-form">
                <select className="form-control ledger" onChange={(e: any) => { setIncomeChange(e.target.value); setpage(1) }}>
                  <option value="">Select Level</option>

                  <option value={1}>Level 1</option>
                  <option value={2}>Level 2</option>
                  <option value={3}>Level 3</option>
                  <option value={4}>Level 4</option>
                  <option value={5}>Level 5</option>
                  <option value={6}>Level 6</option>
                  <option value={7}>Level 7</option>
                  <option value={8}>Level 8</option>
                  <option value={9}>Level 9</option>
                  <option value={10}>Level 10</option>
                  <option value={11}>Level 11</option>
                  <option value={12}>Level 12</option>
                  <option value={13}>Level 13</option>
                  <option value={14}>Level 14</option>
                  <option value={15}>Level 15</option>
                  <option value={16}>Level 16</option>
                  <option value={17}>Level 17</option>
                  <option value={18}>Level 18</option>
                  <option value={19}>Level 19</option>
                  <option value={20}>Level 20</option>



                  <option value="withdraw_request">{t("Withdraw")}</option>
                </select>
              </form>
            </div>
          </div>
          <div className="table_section mt-4">
            <div className="scrolling_table">
              <div className="scroll_under">
                <div className="table_responsive">
                  <Table className="support_table all_tables">
                    <thead className='thead_dashboard'>
                      <tr className="table_heading_row">
                        <th>{t("Date")}</th>
                        <th>{t("Downline ID")}</th>
                        <th>{t("Level")}</th>
                        <th>{t("Package ")}</th>



                      </tr>
                    </thead>
                    <tbody className='tbody_dashboard'>
                      {allIncomes?.length == 0 ? <tr className='no_data_tr'>
                        <td colSpan={6}>
                          <NoResult />
                        </td>
                      </tr> :

                        <>
                          {allIncomes?.map((item: any, index: any) => {
                            return (
                              <>

                                <tr className="table_data_row" key={index}>
                                  <td className="table_data">
                                    <div className="pic_text">{dateFormat(item?.activate_date, "UTC:dd-mm-yyyy hh:mm:ss TT")}</div>
                                  </td>
                                  <td className="table_data"> {item?.downline_id}</td>
                                  <td className="table_data"> {item?.level}</td>
                                  <td className="table_data"> {item?.income_distribution?.toFixed(2)}</td>


                                </tr>
                              </>
                            )
                          })}
                        </>
                      }

                    </tbody>
                  </Table>
                  {totalRecord > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalRecord / 10}
                    previousLabel="<"
                  />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DownlineReport;
